.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Allura&family=Petemoss&family=Praise&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Norican&family=Playfair+Display&display=swap');
.head-text {
  position: relative;
  z-index: 1;
}

.text-on-image {
 position: absolute;
 left: 4%;
 bottom: 10%;
 text-align: center;
 font-size: 8vw;
 font-family: 'Playfair Display', serif;
 background-color: #0c090933;
 color: white;
 transform: translate(-7%, -15%);
 
 
 /*
 -webkit-text-stroke: .5px grey;
 color:rgb(4, 4, 7);
 white-space: nowrap;
 */
}

.page-anchor {
  margin-top: 5px;
}

.custom-input {
  font-size: 30px;
}

.custom-divider {
  padding-right: 100px;
  padding-left: 100px;
}

@media (min-width: 650px) {
  .custom-menu {
    display: none;
  }
}

@media (min-width: 650px) {
  .header-link {
    display: none;
  }
}

@media (max-width: 767px) {
  .main-logo {
    display: true;
    height: auto;
  }
  .collapse-logo {
    display: none;
  }
  .sticky-navbar {
    background-color: #f8f9fa;
  }
  .head-text {
    padding-top: 50px;
  }
}

@media (min-width: 767px) {
  .main-logo {
    display: none;
  }
  .collapse-logo {
    display: true;
  }
  .sticky-navbar {
    background-color: hsla(206, 4%, 66%, 0.212);
  }
}

.sticky-navbar {
  padding: 0px;
}

.divider-line-x {
  position: relative;
  text-align: center; 
  font-family: Arial; 
  font-weight: bold;
  font-size: 12px;
  color: #333;
  border-bottom: 1px solid #ccc;
}

.divider-line-x span {
  position: relative; 
  top: 20px;
  padding: 0 25px;    
  background: #fff;
}

h4 {
  font-family: 'Playfair Display', serif;
}

h2 {
  font-family: 'Playfair Display', serif;
}

.row-image {
  height: 100%;
}

.row-image img{
  max-width: 100%;
  max-height: 100%;
}

.text-column {
  background-color: hsla(206, 4%, 66%, 0.39);
  color: white;
}
html,body{
  overflow-x: hidden;
}
