
.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Allura&family=Petemoss&family=Praise&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Norican&family=Playfair+Display&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Gwendolyn:wght@700&family=Herr+Von+Muellerhoff&family=Love+Light&display=swap');
  
  .head-text {
    position: relative;
    z-index: 1;
  }
  
  .text-on-image {
   position: absolute;
   left: 4%;
   bottom: 10%;
   text-align: center;
   font-size: 8vw;
   font-family: 'Playfair Display', serif;
   background-color: #0c090933;
   color: white;
   transform: translate(-7%, -15%);
   
   
   /*
   -webkit-text-stroke: .5px grey;
   color:rgb(4, 4, 7);
   white-space: nowrap;
   */
  }
  
  .page-anchor {
    margin-top: 0px;
  }
  
  .custom-input {
    font-size: 30px;
  }
  
  .custom-divider {
    padding-right: 100px;
    padding-left: 100px;
  }
  
  @media (min-width: 650px) {
    .custom-menu {
      display: none;
    }
  }
  
  @media (min-width: 650px) {
    .header-link {
      display: none;
    }
  }
  
  @media (max-width: 992px) {
    .main-logo {
      display: block;
      height: auto;
    }
    .collapse-logo {
      display: none;
    }
    .sticky-navbar {
      background-color: #f8f9fa;
    }
    .head-text {
      padding-top: 50px;
    }
  }
  
  @media (min-width: 992px) {
    .main-logo {
      display: none;
    }
    .collapse-logo {
      display: true;
    }
    .sticky-navbar {
      background-color: hsla(206, 4%, 66%, 0.212);
    }
  }
  
  .sticky-navbar {
    padding: 0px;
  }
  
  .divider-line-x {
    position: relative;
    text-align: center; 
    font-family: Arial; 
    font-weight: bold;
    font-size: 12px;
    color: #333;
    border-bottom: 1px solid #ccc;
  }
  
  .divider-line-x span {
    position: relative; 
    top: 20px;
    padding: 0 25px;    
    background: #fff;
  }
  
  h4 {
    font-family: 'Playfair Display', serif;
  }
  
  h2 {
    font-family: 'Playfair Display', serif;
  }

  h3 {
    font-family: "'Playfair Display', serif";
  }
  
  .row-image {
    height: 100%;
  }
  
  .row-image img{
    max-width: 100%;
    max-height: 100%;
  }
  
  .text-column {
    background-color: hsla(206, 3%, 54%, 0.514);
    color: white;
    --bs-gutter-x: 0rem;
  }
  html,body{
    overflow-x: hidden;
  }
  
  .row {
    padding-right: 0px;
    padding-left: 0px;
  }

  .fancy-text-title {
    font-family: 'Gwendolyn', cursive;
    font-size: 60px;
  }

  .fancy-text {
    font-family: 'Gwendolyn', cursive;
  }

  .places-link {
    color: white;
  }

  .theme-text {
    color: #4374a9
  }

  .round-button {
    height: 75px;
    width: 75px;
    background-color: 'white';
    color: 'black'
  }

  .round-button-sm {
    height: 65px;
    width: 65px;
    background-color: 'white';
    color: 'black'
  }

  img.image-gallery-image {
  }

  @import "~react-image-gallery/styles/css/image-gallery.css";